import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SharedCheckrecordListModule } from '../shared-checkrecord-list/shared-checkrecord-list.module';
import { SharedCheckrecordComponent } from './shared-checkrecord.component';

@NgModule({
    declarations: [SharedCheckrecordComponent],
    imports: [
        SharedModule,
        SharedCheckrecordListModule
    ],
    exports: [SharedCheckrecordComponent],
})
export class SharedCheckrecordModule { }