import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SharedCheckrecordListComponent } from './shared-checkrecord-list.component';

@NgModule({
    declarations: [SharedCheckrecordListComponent],
    imports: [SharedModule],
    exports: [SharedCheckrecordListComponent],
})
export class SharedCheckrecordListModule {

}